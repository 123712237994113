import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import { useState } from 'react';

const FiltroModal = ({ filtros = [], onSubmit }) => {
  const [selected, setSelected] = useState(null);
  const { closeModal } = useModal();
  return (
    <>
      <DialogTitle>Filtros</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup onChange={(_, v) => setSelected(v)}>
            {filtros?.map((f) => (
              <FormControlLabel
                key={f?.key}
                value={f?.key}
                label={f?.label}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSubmit(filtros?.find((f) => f?.key === selected))}
        >
          Filtrar
        </Button>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};
export default FiltroModal;
