import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  DataGrid,
  GridActionsCellItem,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import {
  Grid,
  LinearProgress,
  Pagination,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { toastWarning } from 'utils/toast';
import { findOnArray } from 'utils/functions';
import { useModal } from 'components/Modals';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Card from 'components/Card';
import FiltroModal from './Modals/FiltroModal';
import localeText from 'utils/localeText';
import styles from './styles';

const Cfop = () => {
  const rotina = 'CfoRegra';
  const titulo = 'Regras de CFOP';
  const defaultValues = {
    cfo_id: null,
  };
  const [regras, setRegras] = useState([]);
  const [steps, setSteps] = useState([
    { key: 'cfop', label: 'CFOP', value: null, description: '' },
  ]);
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { deleteGrid, deleteLoading, postGrid, postLoading } =
    useContext(GridContext);
  const { control, handleSubmit, watch } = useForm({ defaultValues });
  const last = steps[steps?.length - 1];
  const filtros = [
    // { key: 'tributo_id', label: 'Tributo' },
    // { key: 'operacao', label: 'Operação' },
    // { key: 'transacao', label: 'Transação' },
    // { key: 'planoentidade_id', label: 'Plano / Entidade' },
    // { key: 'situacao_tributaria_id', label: 'Situação Tributária' },
    // { key: 'forma_pagto_id', label: 'Forma de Pagamento' },
    // { key: 'natureza_operacao_id', label: 'Natureza da Operação' },
  ]?.filter((f) => !steps?.some((s) => s?.key === f?.key));

  const loadRegras = () => {
    setRegras([]);
    const params = {};
    steps?.map(({ key, value }) => {
      if (Boolean(key)) {
        params[key] = value;
      }
    });
    // getRegras({ params, cb: setRegras });
  };

  useEffect(() => {
    if (!Boolean(last?.key)) {
      loadRegras();
    }
  }, [last]);

  const CardBlank = () => {
    const changeLastOccurrence = (str = '') => {
      const lastIndex = str?.lastIndexOf(',');
      if (lastIndex === -1) {
        return str;
      }
      return str?.slice(0, lastIndex) + ' e' + str?.slice(lastIndex + 1);
    };
    const rules = steps
      ?.map((s) => s?.description)
      ?.join(', ')
      ?.slice(0, -2);
    const title = `Regras para ${changeLastOccurrence(rules)}`?.toUpperCase();

    // const StatusCell = ({ row }) => {
    //   let ativo = false;
    //   if (!Boolean(row?.inivalid) && !Boolean(row?.fimvalid)) {
    //     ativo = true;
    //   }
    //   if (Boolean(row?.inivalid) && !Boolean(row?.fimvalid)) {
    //     ativo = moment().isSameOrAfter(moment(row?.inivalid));
    //   }
    //   if (!Boolean(row?.inivalid) && Boolean(row?.fimvalid)) {
    //     ativo = moment().isSameOrBefore(moment(row?.fimvalid));
    //   }
    //   if (Boolean(row?.inivalid) && Boolean(row?.fimvalid)) {
    //     ativo =
    //       moment().isSameOrAfter(moment(row?.inivalid)) &&
    //       moment().isSameOrBefore(moment(row?.fimvalid));
    //   }
    //   const props = {
    //     true: {
    //       color: 'success',
    //       label: 'Ativo',
    //       icon: <Check />,
    //     },
    //     false: {
    //       color: 'error',
    //       label: 'Inativo',
    //       icon: <Close />,
    //     },
    //   };
    //   return <Chip size="small" variant="outlined" {...props[ativo]} />;
    // };

    // const ContaCell = ({ row, field }) => {
    //   const lines = [
    //     {
    //       label: 'Complemento',
    //       value: row['cp' + field],
    //     },
    //     {
    //       label: 'Tamanho do Complemento',
    //       value: row['tmcp' + field],
    //     },
    //   ];
    //   return (
    //     <Box flex={1}>
    //       <Typography fontWeight="bold" my={0.5} variant="body1">
    //         {row[field]}
    //       </Typography>
    //       {lines?.map(
    //         ({ label, value }, i) =>
    //           Boolean(value) && (
    //             <Box key={i?.toString()} display="flex">
    //               <Typography flex={1} variant="caption" fontWeight="bold">
    //                 {label}:{' '}
    //               </Typography>
    //               <Typography flex={1} variant="caption">
    //                 {value}
    //               </Typography>
    //             </Box>
    //           )
    //       )}
    //     </Box>
    //   );
    // };

    return (
      <Grid item xs={12} display="flex">
        <Card title={title} style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  openModal(<ManutençãoModal onSubmit={onSubmitRegra} />)
                }
              >
                Adicionar Regra
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                rows={regras}
                columns={[
                  {
                    field: 'actions',
                    type: 'actions',
                    headerName: 'Ações',
                    width: 100,
                    getActions: ({ id, row }) => {
                      return [
                        <GridActionsCellItem
                          icon={<Edit />}
                          label="Editar"
                          onClick={() =>
                            openModal(
                              <ManutençãoModal
                                regra={row}
                                onSubmit={onSubmitRegra}
                              />
                            )
                          }
                        />,
                        <GridActionsCellItem
                          icon={<Delete />}
                          label="Excluir"
                          onClick={() =>
                            openModal(
                              <DeleteModal
                                onSubmit={() =>
                                  deleteGrid({
                                    params: { rotina, id },
                                    cb: () => {
                                      closeModal();
                                      loadRegras();
                                    },
                                  })
                                }
                              />
                            )
                          }
                        />,
                      ];
                    },
                  },
                  // {
                  //   field: 'status',
                  //   type: 'actions',
                  //   headerName: 'Status',
                  //   width: 100,
                  //   sortable: false,
                  //   renderCell: (props) => <StatusCell {...props} />,
                  // },
                  // {
                  //   field: 'cxconta',
                  //   headerName: 'Conta',
                  //   width: 300,
                  //   sortable: false,
                  // },
                  // {
                  //   field: 'bxcontra',
                  //   headerName: 'Contra Conta',
                  //   width: 200,
                  //   sortable: false,
                  // },
                  // {
                  //   field: 'ctacred',
                  //   headerName: 'Conta de Crédito',
                  //   width: 400,
                  //   sortable: false,
                  //   renderCell: (props) => <ContaCell {...props} />,
                  // },
                  // {
                  //   field: 'ctadeb',
                  //   headerName: 'Conta de Débito',
                  //   width: 400,
                  //   sortable: false,
                  //   renderCell: (props) => <ContaCell {...props} />,
                  // },
                ]}
                autoHeight
                pagination
                pageSize={10}
                density="compact"
                disableColumnMenu
                showCellRightBorder
                showColumnRightBorder
                disableSelectionOnClick
                localeText={localeText}
                keepNonExistentRowsSelected
                getRowHeight={() => 'auto'}
                // loading={getLoading || deleteLoading || postLoading}
                components={{
                  Pagination: CustomPagination,
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Grid>
            {Boolean(filtros?.length) && (
              <Grid item xs={12} textAlign="center">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    openModal(
                      <FiltroModal filtros={filtros} onSubmit={onFilter} />
                    )
                  }
                >
                  Continuar Filtrando
                </Button>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const CardCfop = () => {
    const onSubmit = (values) => {
      setSteps((prev) => {
        const newArr = prev?.map((p) => {
          if (p?.key === 'cfop') {
            return {
              ...p,
              value: values?.cfo_id,
              description: findOnArray(values?.cfo_id, drops?.Cfo, 'label'),
            };
          }
          return p;
        });
        newArr?.push({
          key: 'natureza_operacao_id',
          label: 'Natureza da Operação',
          value: null,
          description: '',
        });
        return newArr;
      });
    };

    return (
      <Grid item xs={12} display="flex">
        <Card title="CFOP" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Dropdown
                name="cfo_id"
                label="CFOP"
                control={control}
                options={drops?.Cfo}
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const CardNaturezaOperacao = () => {
    const onSubmit = (values) => {
      onSubmitFiltro({
        key: 'natureza_operacao_id',
        value: values?.natureza_operacao_id,
        description: findOnArray(
          values?.natureza_operacao_id,
          drops?.NaturezaOperacao,
          'label'
        ),
      });
    };

    return (
      <Grid item xs={12} display="flex">
        <Card title="Natureza da Operação" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Dropdown
                name="natureza_operacao_id"
                label="Natureza da Operação"
                control={control}
                options={drops?.NaturezaOperacao}
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  const render = () => {
    switch (last?.key) {
      case 'cfop':
        return <CardCfop />;
      case 'natureza_operacao_id':
        return <CardNaturezaOperacao />;
      case null || '':
        return <CardBlank />;

      default:
        return null;
    }
  };

  const onBack = () => {
    setSteps((prev) => {
      let newArr = [...prev];
      newArr?.pop();
      newArr[newArr?.length - 1]['value'] = null;
      newArr[newArr?.length - 1]['description'] = '';
      return newArr;
    });
  };

  const onFilter = (filtro) => {
    setSteps((prev) => {
      let newArr = [...prev];
      newArr[newArr?.length - 1] = {
        ...newArr[newArr?.length - 1],
        ...filtro,
      };
      return newArr;
    });
    closeModal();
  };

  const onSubmitRegra = (values) => {
    const data = { ...values };
    steps?.map(({ key, value }) => {
      if (Boolean(key)) {
        data[key] = value;
      }
    });
    postGrid({
      data: { data, rotina },
      cb: () => {
        closeModal();
        loadRegras();
      },
    });
  };

  const onSubmitFiltro = ({ key = '', value = null, description = '' }) => {
    if (!Boolean(value)) {
      return toastWarning('Preencha os campos');
    }
    setSteps((prev) => {
      const newArr = prev?.map((p) => {
        if (p?.key === key) {
          return { ...p, value, description };
        }
        return p;
      });
      newArr?.push({
        key: '',
        label: '',
        value: null,
        description: '',
      });
      return newArr;
    });
  };

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  return (
    <Container>
      <Header titulo={titulo} />
      <Grid container spacing={2}>
        <Grid item xs={2} display="flex">
          <Card style={styles?.card}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stepper activeStep={steps?.length - 1} orientation="vertical">
                  {steps
                    ?.filter((f) => Boolean(f?.key))
                    ?.map(
                      ({
                        key = '',
                        label = '',
                        value = null,
                        description = '',
                      }) => (
                        <Step key={key}>
                          <StepLabel>
                            <Typography variant="subtitle2">{label}</Typography>
                            {Boolean(value && description) && (
                              <Typography variant="caption">
                                {description}
                              </Typography>
                            )}
                          </StepLabel>
                        </Step>
                      )
                    )}
                </Stepper>
              </Grid>
              {steps?.length > 1 && (
                <Grid item xs={12} textAlign="center">
                  <Button variant="outlined" color="secondary" onClick={onBack}>
                    Voltar
                  </Button>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={10} display="flex">
          <Grid container spacing={2}>
            {render()}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Cfop;
