import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { findOnArray } from 'utils/functions';
import { dropBoolean } from 'utils/drops';
import { EntidadesContext } from 'contexts/EntidadesContext';
import { useDialog, useModal } from 'components/Modals';
import PesquisaEnderecoModal from 'components/Modals/PesquisaEnderecoModal';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import TableContainer from 'components/TableContainer';
import styles from '../styles';

const Endereços = ({
  drops,
  onSubmit,
  onDelete,
  entidade = {},
  disableActions,
}) => {
  const { openDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const rotina = 'EntidadeEndereco';

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      entidade_id: entidade?.id,
      tipo_endereco_id: item?.tipo_endereco_id || null,
      cep: item?.cep || '',
      principal: item?.principal || 'NAO',
      uf: item?.Cidade?.uf_id || null,
      cidade_id: item?.cidade_id || null,
      bairro: item?.bairro || '',
      logradouro: item?.logradouro || '',
      numero: item?.numero || '',
      complemento: item?.complemento || '',
    };
    const { control, handleSubmit, watch, setValue } = useForm({
      defaultValues,
    });
    const { postLoading } = useContext(EntidadesContext);

    const onSearchAddress = (endereco) => {
      const uf = drops?.UF?.find((u) => u?.sigla === endereco?.uf)?.value;
      const cidade = drops?.Cidade?.find(
        (u) => u?.codigo_ibge === Number(endereco?.ibge)
      )?.value;
      if (Boolean(endereco?.cep)) {
        setValue('cep', endereco?.cep);
      }
      if (Boolean(uf)) {
        setValue('uf', uf);
      }
      if (Boolean(cidade)) {
        setValue('cidade_id', cidade);
      }
      if (Boolean(endereco?.bairro)) {
        setValue('bairro', endereco?.bairro);
      }
      if (Boolean(endereco?.logradouro)) {
        setValue('logradouro', endereco?.logradouro);
      }
      if (Boolean(endereco?.complemento)) {
        setValue('complemento', endereco?.complemento);
      }
      closeModal();
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              openModal(
                <PesquisaEnderecoModal onSubmit={onSearchAddress} />,
                90
              )
            }
          >
            Pesquisar Endereço
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="tipo_endereco_id"
            control={control}
            label="Tipo de Endereço"
            options={drops?.TipoEndereco}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="cep"
            control={control}
            label="CEP"
            mask="99999-999"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="principal"
            control={control}
            label="Principal"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="uf"
            control={control}
            label="Estado"
            options={drops?.UF}
            onValueChange={() => setValue('cidade_id', null)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="cidade_id"
            control={control}
            label="Cidade"
            options={drops?.Cidade?.filter((c) => {
              const estado = findOnArray(watch('uf'), drops?.UF);
              return estado?.sigla === c?.sigla;
            })}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="bairro" control={control} label="Bairro" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="logradouro" control={control} label="Endereço" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="numero" control={control} label="Número" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="complemento" control={control} label="Complemento" />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Enderecos: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Endereços" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDialog(<Modal />, 'Adicionar Endereço')}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        {!!entidade?.EntidadeEnderecos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Tipo de Endereço</TableCell>
                    <TableCell>Logradouro</TableCell>
                    <TableCell>Número</TableCell>
                    <TableCell>Complemento</TableCell>
                    <TableCell>Cidade</TableCell>
                    <TableCell>Bairro</TableCell>
                    <TableCell>CEP</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entidade?.EntidadeEnderecos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.TipoEndereco?.descricao}</TableCell>
                      <TableCell>{item?.logradouro}</TableCell>
                      <TableCell>{item?.numero}</TableCell>
                      <TableCell>{item?.complemento}</TableCell>
                      <TableCell>{item?.Cidade?.localidade}</TableCell>
                      <TableCell>{item?.bairro}</TableCell>
                      <TableCell>{item?.cep}</TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Endereço'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Endereços;
