import { useContext, useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Group } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import { ProdutosMlContext } from 'contexts/ProdutosMlContext';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Button from 'components/Button';
import Card from 'components/Card';
import styles from './styles';
import Input from 'components/Input';

const Configurações = () => {
  const defaultValues = {
    id: null,
    nome_loja: '',
    user_notification: null,
    order_product_search_field: null,
  };
  const [selected, setSelected] = useState(null);
  const [contas, setContas] = useState([]);
  const { drops } = useContext(DropsContext);
  const { getLoading, postLoading, getConfig, postConfig } =
    useContext(ProdutosMlContext);
  const { control, setValue, handleSubmit } = useForm({ defaultValues });

  const linkDrop = [
    { value: 'id', label: 'ID' },
    { value: 'referencia', label: 'REFERÊNCIA' },
  ];

  const loadData = () => {
    getConfig({ cb: setContas });
    setSelected(null);
  };

  useEffect(() => {
    getConfig({ cb: setContas });
  }, []);

  useEffect(() => {
    const conta = contas?.find((f) => f?.id === selected)?.access_data;
    setValue('id', selected || null);
    setValue('nome_loja', conta?.nome_loja || '');
    setValue('user_notification', conta?.user_notification || null);
    setValue(
      'order_product_search_field',
      conta?.order_product_search_field || null
    );
  }, [selected]);

  const onSubmit = ({ id, ...access_data }) =>
    postConfig({ data: { id, access_data }, cb: loadData });

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Configurações da Integração" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <Card title="Contas" style={styles?.card}>
            {Boolean(contas?.length) ? (
              <List>
                {contas?.map((c, i) => (
                  <ListItemButton
                    divider={i + 1 < contas?.length}
                    key={`${c?.id}`}
                    selected={selected === c?.id}
                    onClick={() => setSelected(c?.id)}
                  >
                    <ListItemIcon>
                      <Group />
                    </ListItemIcon>
                    <ListItemText primary={c?.access_data?.nome_loja} />
                  </ListItemButton>
                ))}
              </List>
            ) : (
              <Typography variant="caption">
                Nenhuma conta encontrada
              </Typography>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={8} display="flex">
          <Card title="Configurações" style={styles?.card}>
            {Boolean(selected) ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    name="nome_loja"
                    control={control}
                    label="Nome da Loja"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    name="user_notification"
                    control={control}
                    label="Usuário para receber notificações"
                    options={drops?.Users}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography textAlign="start">
                    Referenciar SKU do produto no mercado livre por:
                  </Typography>
                  <Controller
                    control={control}
                    name="order_product_search_field"
                    render={({ field }) => (
                      <RadioGroup {...field} sx={styles?.group}>
                        {linkDrop?.map((props, i) => (
                          <FormControlLabel
                            {...props}
                            sx={styles?.card}
                            key={`${i}`}
                            control={<Radio />}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                    loading={postLoading}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Typography variant="caption">
                Nenhuma conta selecionada
              </Typography>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Configurações;
