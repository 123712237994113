import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Delete, Upload } from '@mui/icons-material';
import { getImageFromBuffer } from 'utils/functions';
import {
  dropBoolean,
  dropRegimeEspecial,
  dropRegimeTributario,
  dropClassificaçãoTributária,
} from 'utils/drops';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import styles from '../styles';

const Filial = ({
  loading,
  onSubmit,
  onSubmitMidia,
  entidade = {},
  disableActions,
}) => {
  const listCols = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const defaultValues = {
    entidade_id: entidade?.id || null,
    regtrib: entidade?.EntidadeFilial?.regtrib || null,
    regesp: entidade?.EntidadeFilial?.regesp || null,
    pcsimples: entidade?.EntidadeFilial?.pcsimples || '',
    classificacao: entidade?.EntidadeFilial?.classificacao || null,
    token_ibpt: entidade?.EntidadeFilial?.token_ibpt || '',
    spc_servidor: entidade?.EntidadeFilial?.spc_servidor || '',
    spc_porta: entidade?.EntidadeFilial?.spc_porta || '',
    spc_codigo: entidade?.EntidadeFilial?.spc_codigo || '',
    spc_senha: entidade?.EntidadeFilial?.spc_senha || '',
    spc_consulta: entidade?.EntidadeFilial?.spc_consulta || '',
    nfe_crc: entidade?.EntidadeFilial?.nfe_crc || '',
    nfe_crc_uf: entidade?.EntidadeFilial?.nfe_crc_uf || '',
    nfe_crt: entidade?.EntidadeFilial?.nfe_crt || '',
    nfe_senha: entidade?.EntidadeFilial?.nfe_senha || '',
    nfe_certificadopw: entidade?.EntidadeFilial?.nfe_certificadopw || '',
    nfs_usuario: entidade?.EntidadeFilial?.nfs_usuario || '',
    nfs_contribuinte: entidade?.EntidadeFilial?.nfs_contribuinte || '',
    nfs_ccm: entidade?.EntidadeFilial?.nfs_ccm || '',
    nfce_csc: entidade?.EntidadeFilial?.nfce_csc || '',
    nfce_cscseq: entidade?.EntidadeFilial?.nfce_cscseq || '',
    mail_conta: entidade?.EntidadeFilial?.mail_conta || '',
    mail_senha: entidade?.EntidadeFilial?.mail_senha || '',
    mail_email: entidade?.EntidadeFilial?.mail_email || '',
    mail_cc_faturamento: entidade?.EntidadeFilial?.mail_cc_faturamento || '',
    mail_servidorsmtp: entidade?.EntidadeFilial?.mail_servidorsmtp || '',
    mail_portasmtp: entidade?.EntidadeFilial?.mail_portasmtp || '',
    mail_usatls: entidade?.EntidadeFilial?.mail_usatls || null,
  };
  const { control, handleSubmit, getValues } = useForm({ defaultValues });

  const onConfirmMidia = (file, type) => {
    const data = getValues();
    const formData = new FormData();
    formData.append('midia', file);
    formData.append('data', JSON.stringify({ ...data, type }));
    onSubmitMidia(formData);
  };

  const RenderItem = ({ title, type }) => (
    <ImageListItem>
      <Box
        component="img"
        sx={styles.image}
        src={getImageFromBuffer(entidade?.EntidadeFilial[type])}
      />
      <ImageListItemBar
        title={title}
        actionIcon={
          <>
            <IconButton
              size="small"
              onClick={() => document.getElementById('upload-' + type)?.click()}
            >
              <input
                id={'upload-' + type}
                type="file"
                hidden
                onChange={({ target }) => {
                  onConfirmMidia(target.files[0], type);
                  target.value = '';
                }}
              />
              <Upload fontSize="small" sx={styles?.icon} />
            </IconButton>
            <IconButton size="small" onClick={() => onConfirmMidia(null, type)}>
              <Delete fontSize="small" sx={styles?.icon} />
            </IconButton>
          </>
        }
      />
    </ImageListItem>
  );

  return (
    <Card title={entidade?.fantasia} style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ImageList cols={listCols ? 1 : 3}>
            <RenderItem title="Logo" type="logo" />
            <RenderItem title="Assinatura" type="assinatura" />
            <RenderItem title="Certificado NF-e" type="nfe_certificado" />
          </ImageList>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="regtrib"
            control={control}
            label="Regime Tributário"
            options={dropRegimeTributario}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="regesp"
            control={control}
            label="Regime Especial"
            options={dropRegimeEspecial}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="pcsimples" control={control} label="% Simples" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="classificacao"
            control={control}
            label="Classificação do Estabelecimento"
            options={dropClassificaçãoTributária}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="token_ibpt" control={control} label="Token IBPT" />
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Dados para Serviço de Proteção ao Crédito (SPC)
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Input name="spc_servidor" control={control} label="Servidor" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="spc_porta"
            control={control}
            label="Porta"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="spc_codigo" control={control} label="Código" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="spc_senha"
            control={control}
            label="Senha"
            type="password"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="spc_consulta" control={control} label="Consulta" />
        </Grid> */}
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Dados para Nota Fiscal Eletrônica (NF-e)
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="nfe_crc" control={control} label="CRC" type="number" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="nfe_crc_uf"
            control={control}
            label="CRC UF"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="nfe_crt" control={control} label="CRT" type="number" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="nfe_senha"
            control={control}
            label="Senha"
            type="password"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="nfe_certificadopw"
            control={control}
            label="Senha do Certificado"
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Dados para Nota Fiscal de Serviço (NFS-e)
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="nfs_usuario" control={control} label="Usuário" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="nfs_contribuinte"
            control={control}
            label="Contribuinte"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="nfs_ccm"
            control={control}
            label="Inscrição Municipal (CCM)"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Dados para Nota Fiscal de Consumidor Eletrônica (NFC-e)
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="nfce_csc"
            control={control}
            label="Código de Segurança do Contribuinte"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="nfce_cscseq"
            control={control}
            label="Código de Segurança do Contribuinte (Sequência)"
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Dados para envio de E-mail automático
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="mail_conta" control={control} label="Conta do E-mail" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="mail_senha"
            control={control}
            label="Senha"
            type="password"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="mail_email" control={control} label="E-mail" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="mail_cc_faturamento"
            control={control}
            label="Com Cópia"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="mail_servidorsmtp" control={control} label="Servidor" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="mail_portasmtp"
            control={control}
            label="Porta"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="mail_usatls"
            control={control}
            label="Utilizar TLS"
            options={dropBoolean}
          />
        </Grid>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={handleSubmit((Filial) => onSubmit({ Filial }))}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Filial;
