import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ProdutosBlingContext } from 'contexts/ProdutosBlingContext';
import { ProdutosContext } from 'contexts/ProdutosContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Bling = () => {
  const rotina = 'ProdutoBling';
  const titulo = 'Produtos Integrados com Bling';
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { deleteIntegracao } = useContext(ProdutosContext);
  const { produtosBling, getProdutosBling, getLoading } =
    useContext(ProdutosBlingContext);
  const { control, getValues, reset } = useForm({
    defaultValues: produtosBling?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getProdutosBling({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar',
      icon: 'add',
      action: () => navigate('/app/Integrações/Bling/Manutencao'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: produtosBling?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: produtosBling?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={produtosBling?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
  ];
  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) =>
        navigate(`/app/Produtos/Detalhes/${row?.produto_id}`),
    },
    {
      name: 'Editar',
      icon: 'edit',
      action: ({ row }) =>
        navigate('/app/Integrações/Bling/Manutencao', {
          state: row,
        }),
    },
    {
      name: 'Excluir',
      icon: 'delete',
      action: ({ row }) =>
        openModal(
          <DeleteModal
            onSubmit={() =>
              deleteIntegracao({
                id: row?.id,
                path: 'Bling/Produto',
                cb: () => {
                  loadGrid();
                  closeModal();
                },
              })
            }
          />
        ),
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        titulo={titulo}
        colunas={produtosBling?.colunas}
      />
      <Card>
        <Grid
          mock={mock}
          grid={produtosBling}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={produtosBling} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Bling;
