import React from 'react';
import { Route, Routes, Outlet } from 'react-router';
import App from 'views/App';
import Resumo from 'views/Resumo';
import Configurações from 'views/Configurações';
import User from 'views/User';
//ENTIDADE
import Entidades from 'views/Entidades';
import EntidadeDetalhes from 'views/Entidades/Detalhes';
import EntidadeManutenção from 'views/Entidades/Manutenção';
import EntidadeIndicadores from 'views/Entidades/Indicadores';
import EntidadePermissões from 'views/Entidades/Permissões';
//PRODUTO
import Produtos from 'views/Produtos';
import ProdutoDetalhes from 'views/Produtos/Detalhes';
import ProdutoManutenção from 'views/Produtos/Manutenção';
import ProdutoEtiquetas from 'views/Produtos/Etiquetas';
import ProdutoIndicadores from 'views/Produtos/Indicadores';
//INTEGRAÇÕES
import Bling from 'views/Integrações/Bling';
import BlingManutenção from 'views/Integrações/Bling/Manutenção';
import MercadoLivre from 'views/Integrações/MercadoLivre';
import MercadoLivreDetalhes from 'views/Integrações/MercadoLivre/Detalhes';
import MercadoLivreManutenção from 'views/Integrações/MercadoLivre/Manutenção';
import MercadoLivreConfigurações from 'views/Integrações/MercadoLivre/Configurações';
import Shopee from 'views/Integrações/Shopee';
import ShopeeManutenção from 'views/Integrações/Shopee/Manutenção';
//REGRAS
import Preco from 'views/Regras/Preço';
import PrecoManutenção from 'views/Regras/Preço/Manutenção';
import IntegraçãoContábil from 'views/Regras/IntegraçãoContábil';
import Cfop from 'views/Regras/Cfop';
//TABELAS
import Tabelas from 'views/Tabelas';
//TABELAS ENTIDADE
import AtividadeEntidade from 'views/Tabelas/AtividadeEntidade';
import AtividadeEntidadeManutenção from 'views/Tabelas/AtividadeEntidade/Manutenção';
import TipoDocumento from 'views/Tabelas/TipoDocumento';
import TipoDocumentoManutenção from 'views/Tabelas/TipoDocumento/Manutenção';
import TipoEndereco from 'views/Tabelas/TipoEndereco';
import TipoEnderecoManutenção from 'views/Tabelas/TipoEndereco/Manutenção';
import TipoContato from 'views/Tabelas/TipoContato';
import TipoContatoManutenção from 'views/Tabelas/TipoContato/Manutenção';
import TipoVinculo from 'views/Tabelas/TipoVinculo';
import TipoVinculoManutenção from 'views/Tabelas/TipoVinculo/Manutenção';
import Contribuinte from 'views/Tabelas/Contribuinte';
import ContribuinteManutenção from 'views/Tabelas/Contribuinte/Manutenção';
import GrupoEntidade from 'views/Tabelas/GrupoEntidade';
import GrupoEntidadeManutenção from 'views/Tabelas/GrupoEntidade/Manutenção';
import RegiaoEntidade from 'views/Tabelas/RegiaoEntidade';
import RegiaoEntidadeManutenção from 'views/Tabelas/RegiaoEntidade/Manutenção';
import Cidade from 'views/Tabelas/Cidade';
import CidadeManutenção from 'views/Tabelas/Cidade/Manutenção';
import UF from 'views/Tabelas/UF';
import UFManutenção from 'views/Tabelas/UF/Manutenção';
import Pais from 'views/Tabelas/Pais';
import PaisManutenção from 'views/Tabelas/Pais/Manutenção';
import ClasseCnae from 'views/Tabelas/ClasseCnae';
import ClasseCnaeManutenção from 'views/Tabelas/ClasseCnae/Manutenção';
import GrupoCnae from 'views/Tabelas/GrupoCnae';
import GrupoCnaeManutenção from 'views/Tabelas/GrupoCnae/Manutenção';
import DivisaoCnae from 'views/Tabelas/DivisaoCnae';
import DivisaoCnaeManutenção from 'views/Tabelas/DivisaoCnae/Manutenção';
import SecaoCnae from 'views/Tabelas/SecaoCnae';
import SecaoCnaeManutenção from 'views/Tabelas/SecaoCnae/Manutenção';
//TABELAS PRODUTO
import GrupoProduto from 'views/Tabelas/GrupoProduto';
import GrupoProdutoManutenção from 'views/Tabelas/GrupoProduto/Manutenção';
import LinhaProduto from 'views/Tabelas/LinhaProduto';
import LinhaProdutoManutenção from 'views/Tabelas/LinhaProduto/Manutenção';
import Anp from 'views/Tabelas/Anp';
import AnpManutenção from 'views/Tabelas/Anp/Manutenção';
import Marca from 'views/Tabelas/Marca';
import MarcaManutenção from 'views/Tabelas/Marca/Manutenção';
import Modelo from 'views/Tabelas/Modelo';
import ModeloManutenção from 'views/Tabelas/Modelo/Manutenção';
import LocalEstoque from 'views/Tabelas/LocalEstoque';
import LocalEstoqueManutenção from 'views/Tabelas/LocalEstoque/Manutenção';
import UnidadeMedida from 'views/Tabelas/UnidadeMedida';
import UnidadeMedidaManutenção from 'views/Tabelas/UnidadeMedida/Manutenção';
import OrigemTributaria from 'views/Tabelas/OrigemTributaria';
import OrigemTributariaManutenção from 'views/Tabelas/OrigemTributaria/Manutenção';
import Cfiscal from 'views/Tabelas/Cfiscal';
import CfiscalManutenção from 'views/Tabelas/Cfiscal/Manutenção';
//TABELAS OUTRAS
import Contas from 'views/Tabelas/Contas';
import ContaManutenção from 'views/Tabelas/Contas/Manutenção';
import FormasDePagamento from 'views/Tabelas/FormasDePagamento';
import FormasDePagamentoBoleto from 'views/Tabelas/FormasDePagamento/Boleto';
import FormasDePagamentoManutenção from 'views/Tabelas/FormasDePagamento/Manutenção';
import CondicoesDePagamento from 'views/Tabelas/CondicoesDePagamento';
import CondicoesDePagamentoManutenção from 'views/Tabelas/CondicoesDePagamento/Manutenção';
import Moeda from 'views/Tabelas/Moeda';
import MoedaManutenção from 'views/Tabelas/Moeda/Manutenção';
import Planoconta from 'views/Tabelas/Planoconta';
import PlanocontaManutenção from 'views/Tabelas/Planoconta/Manutenção';
import Ccusto from 'views/Tabelas/Ccusto';
import CcustoManutenção from 'views/Tabelas/Ccusto/Manutenção';
import Frota from 'views/Tabelas/Frota';
import FrotaManutenção from 'views/Tabelas/Frota/Manutenção';
import Aplicacao from 'views/Tabelas/Aplicacao';
import AplicacaoManutenção from 'views/Tabelas/Aplicacao/Manutenção';
import Cfo from 'views/Tabelas/Cfo';
import CfoManutenção from 'views/Tabelas/Cfo/Manutenção';
import SituacaoTributaria from 'views/Tabelas/SituacaoTributaria';
import SituacaoTributariaManutenção from 'views/Tabelas/SituacaoTributaria/Manutenção';
//OUTLETS
import OutletEntidade from './Outlets/Entidade';
import OutletProduto from './Outlets/Produto';

const RoutesTree = () => (
  <Routes>
    <Route path="" element={<App />} />
    <Route path="Resumo" element={<Resumo />} />
    <Route path="Configuracoes" element={<Configurações />} />
    <Route path="User" element={<User />} />
    <Route path="Entidades" element={<OutletEntidade />}>
      <Route path="" element={<Entidades />} />
      <Route path="Detalhes/:id" element={<EntidadeDetalhes />} />
      <Route path="Manutencao" element={<EntidadeManutenção />} />
      <Route path="Indicadores/:id" element={<EntidadeIndicadores />} />
      <Route path="Permissoes" element={<EntidadePermissões />} />
    </Route>
    <Route path="Produtos" element={<OutletProduto />}>
      <Route path="" element={<Produtos />} />
      <Route path="Detalhes/:id" element={<ProdutoDetalhes />} />
      <Route path="Manutencao" element={<ProdutoManutenção />} />
      <Route path="Etiquetas" element={<ProdutoEtiquetas />} />
      <Route path="Indicadores/:id" element={<ProdutoIndicadores />} />
    </Route>
    <Route path="Integrações" element={<Outlet />}>
      <Route path="Bling" element={<Outlet />}>
        <Route path="" element={<Bling />} />
        <Route path="Manutencao" element={<BlingManutenção />} />
      </Route>
      <Route path="MercadoLivre" element={<Outlet />}>
        <Route path="" element={<MercadoLivre />} />
        <Route path="Detalhes" element={<MercadoLivreDetalhes />} />
        <Route path="Manutencao" element={<MercadoLivreManutenção />} />
        <Route path="Configuracoes" element={<MercadoLivreConfigurações />} />
      </Route>
      <Route path="Shopee" element={<Outlet />}>
        <Route path="" element={<Shopee />} />
        <Route path="Manutencao" element={<ShopeeManutenção />} />
        <Route path="Manutencao/:id" element={<ShopeeManutenção />} />
      </Route>
    </Route>
    <Route path="Regras" element={<Outlet />}>
      <Route path="Preco" element={<Outlet />}>
        <Route path="" element={<Preco />} />
        <Route path="Manutencao" element={<PrecoManutenção />} />
      </Route>
      <Route path="IntegracaoContabil" element={<IntegraçãoContábil />} />
      <Route path="CFOP" element={<Cfop />} />
    </Route>
    <Route path="Tabelas" element={<Outlet />}>
      <Route path="" element={<Tabelas />} />
      <Route path="AtividadeEntidade" element={<Outlet />}>
        <Route path="" element={<AtividadeEntidade />} />
        <Route path="Manutencao" element={<AtividadeEntidadeManutenção />} />
      </Route>
      <Route path="TipoDocumento" element={<Outlet />}>
        <Route path="" element={<TipoDocumento />} />
        <Route path="Manutencao" element={<TipoDocumentoManutenção />} />
      </Route>
      <Route path="TipoEndereco" element={<Outlet />}>
        <Route path="" element={<TipoEndereco />} />
        <Route path="Manutencao" element={<TipoEnderecoManutenção />} />
      </Route>
      <Route path="TipoContato" element={<Outlet />}>
        <Route path="" element={<TipoContato />} />
        <Route path="Manutencao" element={<TipoContatoManutenção />} />
      </Route>
      <Route path="TipoVinculo" element={<Outlet />}>
        <Route path="" element={<TipoVinculo />} />
        <Route path="Manutencao" element={<TipoVinculoManutenção />} />
      </Route>
      <Route path="Contribuinte" element={<Outlet />}>
        <Route path="" element={<Contribuinte />} />
        <Route path="Manutencao" element={<ContribuinteManutenção />} />
      </Route>
      <Route path="GrupoEntidade" element={<Outlet />}>
        <Route path="" element={<GrupoEntidade />} />
        <Route path="Manutencao" element={<GrupoEntidadeManutenção />} />
      </Route>
      <Route path="RegiaoEntidade" element={<Outlet />}>
        <Route path="" element={<RegiaoEntidade />} />
        <Route path="Manutencao" element={<RegiaoEntidadeManutenção />} />
      </Route>
      <Route path="Cidade" element={<Outlet />}>
        <Route path="" element={<Cidade />} />
        <Route path="Manutencao" element={<CidadeManutenção />} />
      </Route>
      <Route path="UF" element={<Outlet />}>
        <Route path="" element={<UF />} />
        <Route path="Manutencao" element={<UFManutenção />} />
      </Route>
      <Route path="Pais" element={<Outlet />}>
        <Route path="" element={<Pais />} />
        <Route path="Manutencao" element={<PaisManutenção />} />
      </Route>
      <Route path="ClasseCnae" element={<Outlet />}>
        <Route path="" element={<ClasseCnae />} />
        <Route path="Manutencao" element={<ClasseCnaeManutenção />} />
      </Route>
      <Route path="GrupoCnae" element={<Outlet />}>
        <Route path="" element={<GrupoCnae />} />
        <Route path="Manutencao" element={<GrupoCnaeManutenção />} />
      </Route>
      <Route path="DivisaoCnae" element={<Outlet />}>
        <Route path="" element={<DivisaoCnae />} />
        <Route path="Manutencao" element={<DivisaoCnaeManutenção />} />
      </Route>
      <Route path="SecaoCnae" element={<Outlet />}>
        <Route path="" element={<SecaoCnae />} />
        <Route path="Manutencao" element={<SecaoCnaeManutenção />} />
      </Route>
      <Route path="GrupoProduto" element={<Outlet />}>
        <Route path="" element={<GrupoProduto />} />
        <Route path="Manutencao" element={<GrupoProdutoManutenção />} />
      </Route>
      <Route path="LinhaProduto" element={<Outlet />}>
        <Route path="" element={<LinhaProduto />} />
        <Route path="Manutencao" element={<LinhaProdutoManutenção />} />
      </Route>
      <Route path="Anp" element={<Outlet />}>
        <Route path="" element={<Anp />} />
        <Route path="Manutencao" element={<AnpManutenção />} />
      </Route>
      <Route path="Marca" element={<Outlet />}>
        <Route path="" element={<Marca />} />
        <Route path="Manutencao" element={<MarcaManutenção />} />
      </Route>
      <Route path="Modelo" element={<Outlet />}>
        <Route path="" element={<Modelo />} />
        <Route path="Manutencao" element={<ModeloManutenção />} />
      </Route>
      <Route path="LocalEstoque" element={<Outlet />}>
        <Route path="" element={<LocalEstoque />} />
        <Route path="Manutencao" element={<LocalEstoqueManutenção />} />
      </Route>
      <Route path="UnidadeMedida" element={<Outlet />}>
        <Route path="" element={<UnidadeMedida />} />
        <Route path="Manutencao" element={<UnidadeMedidaManutenção />} />
      </Route>
      <Route path="OrigemTributaria" element={<Outlet />}>
        <Route path="" element={<OrigemTributaria />} />
        <Route path="Manutencao" element={<OrigemTributariaManutenção />} />
      </Route>
      <Route path="Cfiscal" element={<Outlet />}>
        <Route path="" element={<Cfiscal />} />
        <Route path="Manutencao" element={<CfiscalManutenção />} />
      </Route>
      <Route path="Contas" element={<Outlet />}>
        <Route path="" element={<Contas />} />
        <Route path="Manutencao" element={<ContaManutenção />} />
      </Route>
      <Route path="FormasDePagamento" element={<Outlet />}>
        <Route path="" element={<FormasDePagamento />} />
        <Route path="Boleto" element={<FormasDePagamentoBoleto />} />
        <Route path="Manutencao" element={<FormasDePagamentoManutenção />} />
      </Route>
      <Route path="CondicoesDePagamento" element={<Outlet />}>
        <Route path="" element={<CondicoesDePagamento />} />
        <Route path="Manutencao" element={<CondicoesDePagamentoManutenção />} />
      </Route>
      <Route path="Moeda" element={<Outlet />}>
        <Route path="" element={<Moeda />} />
        <Route path="Manutencao" element={<MoedaManutenção />} />
      </Route>
      <Route path="Planoconta" element={<Outlet />}>
        <Route path="" element={<Planoconta />} />
        <Route path="Manutencao" element={<PlanocontaManutenção />} />
      </Route>
      <Route path="Ccusto" element={<Outlet />}>
        <Route path="" element={<Ccusto />} />
        <Route path="Manutencao" element={<CcustoManutenção />} />
      </Route>
      <Route path="Frota" element={<Outlet />}>
        <Route path="" element={<Frota />} />
        <Route path="Manutencao" element={<FrotaManutenção />} />
      </Route>
      <Route path="Aplicacao" element={<Outlet />}>
        <Route path="" element={<Aplicacao />} />
        <Route path="Manutencao" element={<AplicacaoManutenção />} />
      </Route>
      <Route path="Cfop" element={<Outlet />}>
        <Route path="" element={<Cfo />} />
        <Route path="Manutencao" element={<CfoManutenção />} />
      </Route>
      <Route path="SituacaoTributaria" element={<Outlet />}>
        <Route path="" element={<SituacaoTributaria />} />
        <Route path="Manutencao" element={<SituacaoTributariaManutenção />} />
      </Route>
    </Route>
  </Routes>
);

export default RoutesTree;
