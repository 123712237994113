import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  cfo: '',
  orgdest: [],
  descricao: '',
  orientacao: '',
  servico: null,
  planoconta: [],
};

export const CfoContext = createContext();

export const CfoProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [cfo, setCfo] = useState({
    data: [],
    colunas: [],
    filter,
    page: 0,
    size: 10,
  });

  const getCfo = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: cfo?.filter,
        page: cfo?.page,
        size: cfo?.size,
        order: cfo?.order,
        ...payload,
      });
      setCfo((prev) => ({ ...prev, ...payload, ...data }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <CfoContext.Provider
      value={{
        getLoading,
        cfo,
        getCfo,
      }}
    >
      {children}
    </CfoContext.Provider>
  );
};
